import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import CenterBreadCrumbs from "../../components/centerBreadCrumbs"

import Image from "../../components/image"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "jki2/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "jki2/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "jki2/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFour: file(relativePath: { eq: "jki2/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFive: file(relativePath: { eq: "jki2/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSix: file(relativePath: { eq: "jki2/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const galleryImages = [
    {
      image: data.imageOne.childImageSharp.fluid,
      caption: `Playing alongside my friends.`,
    },
    {
      image: data.imageTwo.childImageSharp.fluid,
      caption: `Off to explore.`,
    },
    {
      image: data.imageThree.childImageSharp.fluid,
      caption: `Exploring with sand.`,
    },
    {
      image: data.imageFour.childImageSharp.fluid,
      caption: `Gardening with Chloe.`,
    },
    {
      image: data.imageFive.childImageSharp.fluid,
      caption: `Construction in the sandpit.`,
    },
    {
      image: data.imageSix.childImageSharp.fluid,
      caption: `Making sense of our living world.`,
    },
  ]

  return (
    <>
      <SEO title="Just Kidd Inn Too - Gallery" />

      <CenterBreadCrumbs
        centerName="Just Kiddinn Too"
        centerUrl="/justkiddinntoo/"
        subPageName="Gallery"
      />

      <div class="container-fluid horizontal-section-container clearfix">
        <div class="row">
          <div class="col-sm-12">
            <div
              id="main-page-content"
              class="section-container main-page-content clearfix"
            >
              <div class="section-content">
                <h1 class="page_title">Gallery</h1>

                <div id="content">
                  <div class="thumbnail-list row clearfix">
                    {galleryImages.map(galleryImage => {
                      return (
                        <div class="item col-sm-4">
                          <div class="thumbnail item-content">
                            <Image image={galleryImage.image} />
                            <div class="caption">
                              <p>{galleryImage.caption}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Gallery
